import React from "react"

import Layout from "../layout/layout"
import Button from "../components/button"

import infoEmail from "../images/info-email.png"

const Contact = () => {
  return (
    <Layout
      title="Contact us"
      description="Contact the Summit Institute team via email"
    >
      <div className="contact-wrapper">
        <div className="contact-background-wrapper">
          <div className="contact-background-left"></div>
          <div className="contact-background-right"></div>
        </div>
        <div className="contact-content-wrapper">
          <div className="contact-content-container">
            <div className="contact-content">
              <h3 className="contact-heading">We'd love to hear from you</h3>
              <span style={{ marginBottom: "10px" }}>
                You can contact us at
              </span>
              <img
                src={infoEmail}
                style={{ marginBottom: "20px" }}
                alt="email address"
              />
              <span style={{ marginBottom: "20px" }}>
                and one of our team will be in touch
              </span>
              <a href="mailto:contact@summitinstitute.ac.nz">
                <Button colorSchema="sec">Email us</Button>
              </a>
            </div>
            <br />
            <div className="contact-content">
              <span style={{ marginBottom: "10px" }}>
                You can also ring us on
              </span>
              <h4>+64 21 1213097</h4>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
